import React, { useContext } from 'react';
import { useState } from 'react';
import { WalletType } from 'types';
import { albedo, freighter, rabet } from 'utils/wallet';

export interface IWalletContext {
	connected: boolean;
	walletAddress: Maybe<string>;
	walletType: Maybe<WalletType>;
	connect: (_walletType: WalletType) => void;
	signTransaction: (xdr: string) => Promise<string>;
}

const WalletContext = React.createContext<Maybe<IWalletContext>>(null);

export const WalletProvider = ({ children = null as any }) => {
	const [connected, setConnected] = useState<boolean>(false);
	const [walletAddress, setWalletAddress] = useState<Maybe<string>>(null);
	const [walletType, setWalletType] = useState<Maybe<WalletType>>(null);

	/**
	 * Connects a browser wallet by fetching the public key from the wallet.
	 * @param _walletType - The wallet we are trying to connect
	 * @returns The public key of the wallet
	 */
	async function connect(_walletType: WalletType) {
		try {
			let address = null;
			switch (_walletType) {
				case WalletType.Freighter:
					address = await freighter.connect();
					break;
				case WalletType.Albedo:
					address = await albedo.connect();
					break;
				case WalletType.Rabet:
					address = await rabet.connect();
					break;
			}
			setWalletAddress(address);
			setWalletType(_walletType);
			setConnected(true);
		} catch (e) {
			console.error(e);
			setWalletAddress(null);
			setConnected(false);
			throw e;
		}
	}

	/**
	 * Signs a transaction with a connected wallet.
	 * @param xdr = The xdr-encoded transaction envelope to sign.
	 * @returns The signed_xdr of the transaction
	 */
	async function signTransaction(xdr: string): Promise<string> {
		try {
			switch (walletType) {
				case WalletType.Freighter:
					return await freighter.sign(xdr);
				case WalletType.Albedo:
					return await albedo.sign(xdr);
				case WalletType.Rabet:
					return await rabet.sign(xdr);
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
		return '';
	}

	return (
		<WalletContext.Provider value={{ connected, walletAddress, walletType, connect, signTransaction }}>
			{children}
		</WalletContext.Provider>
	);
};

export const useWallet = () => {
	const context = useContext(WalletContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};

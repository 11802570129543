import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<{
	variant?: 'primary' | 'secondary';
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 40px;
	color: ${({ theme, variant }) => (variant === 'secondary' ? theme.colors.blue3 : theme.colors.white)};
	background: ${({ theme, variant }) => (variant === 'secondary' ? theme.colors.light_blue1 : theme.colors.blue1)};
	box-shadow: 0 3px 6px ${({ theme }) => theme.colors.shadow};
	padding: 0.5rem 2rem;
	font-size: 1.5rem;
	line-height: 150%;
	border: none;
	outline: none;

	&:hover {
		background: ${({ theme, variant }) => (variant === 'secondary' ? theme.colors.light_blue2 : theme.colors.blue2)};
	}

	&:disabled {
		opacity: 0.15;
		cursor: not-allowed;
	}
`;

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary'; // primary as default
}

export const Button: React.FC<IProps> = ({ children, ...props }) => {
	return <StyledButton {...props}>{children}</StyledButton>;
};

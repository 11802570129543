import React from 'react';
import styled from 'styled-components';
import { Modal, IModalProps, Button } from 'components';
import { WalletType } from 'types';
import { toast } from 'react-toastify';

import freighterIcon from 'assets/images/icn_freighter.svg';
import albedoIcon from 'assets/images/icn_albedo.svg';
import rabetIcon from 'assets/images/icn_rabet.svg';
import { FriendbotBuilder } from 'stellar-sdk/lib/friendbot_builder';

const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	> h3 {
		margin: 20px 0;
	}

	> button {
		width: 100%;
		height: 100px;
		font-size: 36px;
		line-height: 47px;
		margin-bottom: 10px;
	}
`;

const WalletButton = styled(Button)`
  width: 100%;
  height: 100px;
  font-size: 36px;
  line-height: 47px;
  margin-bottom: 10px;
  display: flex;
	align-items: center;
  justify-content: space-between;

  > img,
  input {
    height: 60px;
  }

  > p,
  input {
    margin-right: 40px;
  }
`;

interface IProps extends IModalProps {
	onConnect: (type: WalletType) => Promise<void>;
}

const handleConnect = async (onConnect: (arg0: WalletType) => Promise<void>, walletType: WalletType) => {
  try {
    await onConnect(walletType);
  } catch (e) {
    toast.error(`Unable to connect wallet. ${e}`);
  }
};

export const ConnectWalletModal: React.FC<IProps> = ({ open, onClose, onConnect }) => {
	return (
		<Modal open={open} onClose={onClose} width={600} height={640}>
			<Content>
				<h3>Connect Wallet</h3>
        <WalletButton variant="secondary" onClick={async () => await handleConnect(onConnect, WalletType.Freighter)}>
          <img src={freighterIcon} alt="" />
					<p>Freighter</p>
        </WalletButton>
        <WalletButton variant="secondary" onClick={async () => await handleConnect(onConnect, WalletType.Albedo)}>
          <img src={albedoIcon} alt="" />
					<p>Albedo</p>
        </WalletButton>
        <WalletButton variant="secondary" onClick={async () => await handleConnect(onConnect, WalletType.Rabet)}>
          <img src={rabetIcon} alt="" />
					<p>Rabet</p>
        </WalletButton>
				<a target="help" href="https://script3.io/scriptees-help" style={{ marginTop: 100 }}>
					Wallet Help
				</a>
			</Content>
		</Modal>
	);
};

import { Account, TransactionBuilder, Memo, Networks, Operation, AccountResponse, Server } from 'stellar-sdk';
import { v4 as uuid } from 'uuid';
import { ScripteeTransaction, ShippingInformation } from 'types';
import axios from 'axios';
import { SCRIPTEE, SERVER_URL } from 'config/constants';

const stellar = new Server(SERVER_URL);

/**
 * Get a users Account via their public key
 * @param publicKey The public key or address for a Stellar Account
 * @return {Promise<AccountResponse>} Account Response of the user
 */
export async function getAccountAsync(publicKey: string): Promise<AccountResponse> {
	try {
		return await stellar.loadAccount(publicKey);
	} catch (e) {
		console.log(`Failure getting the account for ${publicKey}`);
		throw e;
	}
}

/**
 * Gets the SCRIPTEE balance of a user
 * @param {string} publicKey The account public key to check the balance of
 * @returns {Promise<number>} The SCRIPTEE balance
 */
export async function getScripteeBalanceAsync(publicKey: string): Promise<number> {
	try {
		let account = await getAccountAsync(publicKey);
		if (!account) {
			throw new ReferenceError('Not a valid or complete account record');
		}
		return getScripteeBalanceForAccount(account);
	} catch (e) {
		console.log(`Failed to determine SCRIPTEE balance for ${publicKey}`);
		throw e;
	}
}

/**
 * Gets the SCRIPTEE balance of a user
 * @param {string} publicKey The account public key to build the transaction for
 * @returns {Promise<ScripteeTransaction>} A transaction object
 */
export async function buildScripteePaymentAsync(publicKey: string): Promise<ScripteeTransaction> {
	try {
		let account = await getAccountAsync(publicKey);
		if (!account) {
			throw new ReferenceError('Not a valid or complete account record');
		}
		let scripteeBalance = getScripteeBalanceForAccount(account);
		if (scripteeBalance == 0) throw new Error('Invalid SCRIPTEE balance');

    let operationUid = uuid();
    operationUid = operationUid.substring(0, 28); // stellar memos only support 28 bytes
    let baseFee = await stellar.fetchBaseFee();
    
		let userAccount = new Account(account.account_id, account.sequence);
		let txBuilder = new TransactionBuilder(userAccount, {
			memo: Memo.text(operationUid),
			fee: (baseFee * 2).toFixed(5),
			networkPassphrase: Networks.PUBLIC
		});
		txBuilder.addOperation(
			Operation.payment({
				destination: SCRIPTEE.issuer,
				amount: '0.0000001',
				asset: SCRIPTEE,
			})
		);
    let xdr = txBuilder
                .setTimeout(300) // wait max 5 min for transaction to be submitted
                .build()
                .toXDR();
		let scripteeTransaction: ScripteeTransaction = {
			xdr: xdr,
			operationUid: operationUid,
			publicKey: account.account_id,
		};
		return scripteeTransaction;
	} catch (e) {
    console.error(e);
		console.log(`Failed to create SCRIPTEE payment for ${publicKey}`);
		throw e;
	}
}

/**
 * Submits an order to Script3.
 *
 * @remarks
 * The ScripteeTransaction will be submitted during this phase.
 * Please ensure the `signed_xdr` field is accurate. Further, this means
 * this function will take a few seconds to process.
 *
 * @param tx - ScripteeTransaction information
 * @param info - Shipping Information
 */
export async function sendScripteeOrder(tx: ScripteeTransaction, info: ShippingInformation) {
	let hash = await submitTransaction(tx);
  console.log(`Submitted transaction with hash ${hash} to Stellar - op:${tx.operationUid}`);
	// Sadly, should probably wait for the transaction to be accepted.
	// wait 5 seconds to ensure the block is updated :)
	await new Promise(r => setTimeout(r, 1000));

	// send the confirmation email
	try {
		await axios.post('https://scriptees-core.script3.workers.dev/redeem', {
			txInfo: tx,
			shippingInfo: info,
		});
	} catch (e) {
		throw new Error(`Unable to redeem token. ${e}`);
	}
}

/*******************
 * Private Helpers *
 *******************/

/**
 * Get the number of SCRIPTEE tokens an account has
 * @param {AccountResponse} account The account response to search
 * @returns {number} The amount of SCRIPTEE tokens the acconut has
 */
function getScripteeBalanceForAccount(account: AccountResponse): number {
	if (!account.balances) throw new ReferenceError('Account has no balances');
	let scripteeBalanceLine = account.balances.find(b => {
		// typing is broken in stellar-sdk for BalanceLines :(
		let _b: any = b;
		return _b.asset_type !== 'native' && _b.asset_code === SCRIPTEE.code && _b.asset_issuer === SCRIPTEE.issuer;
	});
	return !scripteeBalanceLine?.balance ? 0 : +scripteeBalanceLine?.balance;
}

/**
 * Submits a ScripteeTransaction to the Stellar Network.
 * @param txInfo - The trasaction info to submit
 * @returns {string} The transaction hash that was submitted
 */
async function submitTransaction(txInfo: ScripteeTransaction): Promise<string> {
	if (!txInfo.signed_xdr) throw new Error('No signed_xdr provided');
	let signedTransaction = TransactionBuilder.fromXDR(txInfo.signed_xdr, SERVER_URL);
	let response = await stellar.submitTransaction(signedTransaction);
	return response.hash;
}

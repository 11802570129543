export interface ScripteeTransaction {
	xdr: string;
	signed_xdr?: string;
	operationUid: string;
	publicKey: string;
}

export interface ShippingInformation {
	size: string;
	country: string;
	name: string;
	addressLn1: string;
	addressLn2?: string;
	city: string;
	stateProvinceRegion: string;
	zipCode: string;
  phoneNumber: string;
  email: string;
}

export enum WalletType {
	Freighter,
	Albedo,
	Rabet,
}

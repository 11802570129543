import React from 'react';
import styled from 'styled-components';
import { Header } from 'components';
import { OrderCard } from './OrderCard';
import { DetailCard } from './DetailCard';

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	background: ${({ theme }) => theme.colors.background};
`;

const Content = styled.div`
	width: 100%;
	min-height: calc(100% - 6.25rem);
	padding-bottom: 6.25rem;
	display: flex;
	justify-content: center;
	align-items: center;

	${({ theme }) => theme.mediaWidth.upToSmall`
		flex-direction: column;
	`}
`;

export const Main = () => {
	return (
		<Container>
			<Header />
			<Content>
				<OrderCard />
				<DetailCard />
			</Content>
		</Container>
	);
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card } from 'components';
import { useWallet } from 'contexts/wallet';
import { WalletType } from 'types';
import { ConnectWalletModal, RedeemModal } from './modals';

import connectedIcon from 'assets/images/icn_connected.svg';
import freighterIcon from 'assets/images/icn_freighter.svg';
import albedoIcon from 'assets/images/icn_albedo.svg';
import rabetIcon from 'assets/images/icn_rabet.svg';
import { useScriptee } from 'contexts/scriptee';

const Container = styled(Card)`
	width: 35rem;
	min-width: 35rem;
	height: 35rem;
	margin: 2.5rem;

	> button,
	input {
		width: 100%;
		height: 5rem;
		font-size: 2rem;
		line-height: 120%;
		margin-top: 5.5rem;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	height: 5rem;
	font-size: 2rem;
	line-height: 120%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	> button,
	input {
		width: 48%;
		height: 5rem;
		font-size: 2rem;
		line-height: 120%;
		margin-top: 2.5rem;
	}
`;

const WalletAddress = styled.div`
	width: 100%;
	height: 5rem;
	margin-top: 2.5rem;
	padding: 0.5rem 1.5rem;
	font-size: 2rem;
	line-height: 120%;
	border: 1px solid ${({ theme }) => theme.colors.dark_grey};
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	> img,
	input {
		height: 3.5rem;
	}
`;

const ScripteeBalance = styled.div`
	width: 100%;
	padding: 0 1.25rem;
	margin-top: 1.25rem;
	font-size: 2rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const OrderCard = () => {
	const { walletAddress, connected, connect, walletType } = useWallet();
	const { balance } = useScriptee();

	const [showRedeemModal, setShowRedeemModal] = useState(false);
	const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);

	const getShortenWalletAddress = () => {
		if (!walletAddress) {
			return '';
		}

		return `0x${walletAddress.substr(0, 4)}...${walletAddress.substr(walletAddress.length - 4, walletAddress.length)}`;
	};

	const getWalletLogo = () => {
		switch (walletType) {
			case WalletType.Freighter:
				return freighterIcon;
			case WalletType.Albedo:
				return albedoIcon;
			case WalletType.Rabet:
				return rabetIcon;
			default:
				return connectedIcon;
		}
	};

	return (
		<>
			<Container>
				<h3 style={{ margin: '2rem 0' }}>An NFT-Shirt</h3>
				<p>
					Claim your exclusive ScripTee! A physical t-shirt tied to a unique non-fungible token. 100 limited-edition
					NFTees will be issued.
				</p>
				{connected ? (
					<>
						<WalletAddress>
							<img src={getWalletLogo()} alt="" />
							<p>{getShortenWalletAddress()}</p>
						</WalletAddress>
						<ScripteeBalance>{balance} SCRIPTEE</ScripteeBalance>
					</>
				) : (
					<Button variant="secondary" onClick={() => setShowConnectWalletModal(true)}>
						Connect Wallet
					</Button>
				)}
				<ButtonContainer >
					<Button variant="primary" disabled={!connected || (connected && balance == 0)} onClick={() => setShowRedeemModal(true)}>
						Redeem
					</Button>
					<Button
						type="button"
						variant="secondary"
						onClick={() =>
							window.open(
								'https://stellar.expert/explorer/public/asset/SCRIPTEE-GCRIHDMU4MEW7RQX5YPWEMAFDJZU4JTZZ6FYZU2TBDYL254TJF6CTWPJ',
								'scriptee'
							)
						}
					>
						View Token
					</Button>
				</ButtonContainer>
			</Container>

			<RedeemModal open={showRedeemModal} onClose={() => setShowRedeemModal(false)} />
			<ConnectWalletModal
				open={showConnectWalletModal}
				onClose={() => setShowConnectWalletModal(false)}
				onConnect={async (type: WalletType) => {
					setShowConnectWalletModal(false);
					await connect(type);
				}}
			/>
		</>
	);
};

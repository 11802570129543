/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { ScripteeTransaction, ShippingInformation, WalletType } from 'types';
import { buildScripteePaymentAsync, getScripteeBalanceAsync, sendScripteeOrder } from 'utils/scriptee';
import { useWallet } from './wallet';

export interface IScripteeContext {
	balance: number;
	updateBalance: () => void;
	redeemScriptee: (shippingInfo: ShippingInformation) => void;
}

const ScripteeContext = React.createContext<Maybe<IScripteeContext>>(null);

export const ScripteeProvider = ({ children = null as any }) => {
	const { walletAddress, signTransaction } = useWallet();

	const [balance, setBalance] = useState<number>(0);

	useEffect(() => {
		if (walletAddress) {
			updateBalance();
		}
	}, [walletAddress]);

	const updateBalance = async () => {
		if (!walletAddress) {
			setBalance(0);
			return;
		}

		try {
			const res = await getScripteeBalanceAsync(walletAddress);
			setBalance(res);
		} catch (e) {
			setBalance(0);
		}
	};

	const redeemScriptee = async (shippingInfo: ShippingInformation) => {
		if (!walletAddress) {
			return;
		}

    const tx: ScripteeTransaction = await buildScripteePaymentAsync(walletAddress);
    tx.signed_xdr = await signTransaction(tx.xdr);
    if (tx) {
      await sendScripteeOrder(tx, shippingInfo);
    }
	};

	return (
		<ScripteeContext.Provider value={{ balance, updateBalance, redeemScriptee }}>{children}</ScripteeContext.Provider>
	);
};

export const useScriptee = () => {
	const context = useContext(ScripteeContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};

import React from 'react';
import styled from 'styled-components';
import { Card } from 'components';

import shirtImg from 'assets/images/shirt.png';

const Container = styled(Card)`
	width: 35rem;
	min-width: 35rem;
	height: 35rem;
	margin: 2.5rem;
	background: ${({ theme }) => theme.colors.light_blue2};
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	> h4,
	p {
		margin: 0;
	}
`;

const ShirtImg = styled.img`
	width: 100%;
`;

const Details = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 1.25rem;
`;

export const DetailCard = () => {
	return (
		<Container>
			<ShirtImg src={shirtImg} alt="" />
			<Details>
				<p>
					<b>$SCRIPTEE</b>
				</p>
			</Details>
		</Container>
	);
};

import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Select, Modal, IModalProps } from 'components';
import { CircularProgress } from '@material-ui/core';
import { ShippingInformation } from 'types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useScriptee } from 'contexts/scriptee';
import { toast } from 'react-toastify';

const Content = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	> h3 {
		margin: 20px 0;
	}

	> h4 {
		margin: 10px 0;
	}

	> input {
		width: 100%;
		margin-top: 10px;
	}

	> button {
		margin-top: 10px;
	}
`;

const ContentRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	> input {
		width: 49%;
		margin-top: 10px;
	}

	> p {
		margin: 5px 0;
	}
`;

const FormContent = styled.form`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	> h3 {
		margin: 20px 0;
	}

	> h4 {
		margin: 10px 0;
	}

	> input {
		width: 100%;
		margin-top: 10px;
	}

	> button {
		width: 40%;
    height: 3rem;
    min-height: 44px;
		line-height: 120%;
    margin-top: 2.5rem;
    font-size: 24px;
	}
`;

const SizeSelect = styled(Select)`
	width: 100%;
`;

const AddressTitle = styled.div`
	width: 100%;
	font-size: 24px;
	line-height: 31px;
	color: ${({ theme }) => theme.colors.dark_grey};
	margin-left: 20px;
	margin-top: 20px;
	text-align: left;
`;

const ProgressWrapper = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const SuccessWrapper = styled.div`
	border-radius: 40px;
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.green};
	padding: 8px 60px;
	margin-bottom: 10px;
`;

export const RedeemModal: React.FC<IModalProps> = ({ open, onClose }) => {
	const { redeemScriptee } = useScriptee();

	const [showConfirm, setShowConfirm] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isCompleted, setCompleted] = useState(false);
	const [shippingInfo, setShippingInfo] = useState<Maybe<ShippingInformation>>(null);

	const handleClose = () => {
		setShowConfirm(false);
		setLoading(false);
		setCompleted(false);
		onClose();
	};

	const handleConfirm = async () => {
		if (!shippingInfo) {
			return;
		}

		setLoading(true);

		try {
			await redeemScriptee(shippingInfo);
			setCompleted(true);
      toast.success(`Success! Check ${shippingInfo.email} for confirmation.`);
		} catch (e) {			
      toast.error('Whoops! Something went wrong...');
		}

		setLoading(false);
	};

	return (
		<Modal open={open} onClose={handleClose} width={700} height={650}>
			<Content>
				<h4>Redeem</h4>

				<RedeemForm
					show={!showConfirm}
					onNext={(data: ShippingInformation) => {
						setShippingInfo(data);
						setShowConfirm(true);
					}}
				/>

				{showConfirm && shippingInfo && (
					<RedeemConfirm
						shippingInfo={shippingInfo}
						loading={isLoading}
						completed={isCompleted}
						onBack={() => setShowConfirm(false)}
						onConfirm={handleConfirm}
					/>
				)}
			</Content>
		</Modal>
	);
};

interface IRedeemFormProps {
	show: boolean;
	onNext: (shippingInfo: ShippingInformation) => void;
}

const schema = yup.object().shape({
	size: yup.string().required().default('Small'),
	country: yup.string().required(),
	name: yup.string().required(),
	addressLn1: yup.string().required(),
	addressLn2: yup.string(),
	city: yup.string().required(),
	stateProvinceRegion: yup.string().required(),
	zipCode: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().email().required(),
});

const RedeemForm: React.FC<IRedeemFormProps> = ({ show, onNext }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });

	const handleNext = (data: ShippingInformation) => {
		onNext(data);
	};

	return show ? (
		<FormContent onSubmit={handleSubmit(handleNext)}>
			<SizeSelect options={['Small', 'Medium', 'Large', 'XL']} {...register('size')} />
      <Input placeholder="Email *" {...register('email')} error={!!errors.email} />

			<AddressTitle>Address</AddressTitle>
			<Input placeholder="Country *" {...register('country')} error={!!errors.country} />
			<Input placeholder="Full Name *" {...register('name')} error={!!errors.name} />
			<Input placeholder="Address Line 1 *" {...register('addressLn1')} error={!!errors.addressLn1} />
			<Input placeholder="Address Line 2" {...register('addressLn2')} error={!!errors.addressLn2} />
			<ContentRow>
				<Input placeholder="City *" {...register('city')} error={!!errors.city} />
				<Input
					placeholder="State/Province/Region *"
					{...register('stateProvinceRegion')}
					error={!!errors.stateProvinceRegion}
				/>
			</ContentRow>
			<ContentRow>
				<Input placeholder="Zip Code *" {...register('zipCode')} error={!!errors.zipCode} />
				<Input placeholder="Phone Number *" {...register('phoneNumber')} error={!!errors.phoneNumber} />
			</ContentRow>

			<Button type="submit" variant="primary">
				Redeem
			</Button>
		</FormContent>
	) : null;
};

interface IRedeemConfirmProps {
	shippingInfo: ShippingInformation;
	loading: boolean;
	completed: boolean;
	onBack: () => void;
	onConfirm: () => void;
}

const RedeemConfirm: React.FC<IRedeemConfirmProps> = ({ shippingInfo, loading, completed, onBack, onConfirm }) => {
	return (
		<>
			<h4>Confirm Shipping Information</h4>
			<ContentRow>
				<p>
					Size: <b>{shippingInfo.size}</b>
				</p>
				{!completed && (
					<Button variant="secondary" onClick={onBack} disabled={loading}>
						Change
					</Button>
				)}
			</ContentRow>
      <ContentRow>
				<p>
					Email: <b>{shippingInfo.email}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					Country: <b>{shippingInfo.country}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					Name: <b>{shippingInfo.name}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					Address Line 1: <b>{shippingInfo.addressLn1}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					Address Line 2: <b>{shippingInfo.addressLn2}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					City: <b>{shippingInfo.city}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					State/Province/Region: <b>{shippingInfo.stateProvinceRegion}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					Zip Code: <b>{shippingInfo.zipCode}</b>
				</p>
			</ContentRow>
			<ContentRow>
				<p>
					Phone Number: <b>{shippingInfo.phoneNumber}</b>
				</p>
			</ContentRow>

			{completed ? (
				<ProgressWrapper>
					<SuccessWrapper>Success!</SuccessWrapper>
					<span>Your ScripTee is on its way!</span>
				</ProgressWrapper>
			) : !loading ? (
				<Button variant="primary" onClick={onConfirm}>
					Redeem
				</Button>
			) : (
				<ProgressWrapper>
					<CircularProgress />
					<span>Approve the transaction in your wallet</span>
				</ProgressWrapper>
			)}
		</>
	);
};
